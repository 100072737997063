<template>
  <div>
    <a
      class="has-text-weight-medium has-text-right is-block"
      @click="openModal"
      >{{ $t("af:profile.modal.name.button") }}</a
    >

    <b-modal v-model="isModalOpen" :can-cancel="false" :width="500">
      <div class="af-modal-card">
        <p class="has-text-weight-bold is-size-4 mb-6">
          {{ $t("af:profile.modal.name.title") }}
        </p>

        <form @submit.prevent="save">
          <validation-observer ref="observer">
            <BInputWithValidation
              rules="required"
              :label="$t('af:profile.modal.name.input.last_name.label')"
              label-position="on-border"
              v-model="lastNameEdit"
              :allow-space="true"
            />

            <BInputWithValidation
              rules="required"
              :label="$t('af:profile.modal.name.input.first_name.label')"
              label-position="on-border"
              v-model="firstNameEdit"
              :allow-space="true"
            />

            <b-field grouped class="mt-4">
              <b-button
                type="is-blue"
                :value="$t('af:profile.modal.name.button.save')"
                tag="input"
                @click="save"
              ></b-button>

              <b-button type="is-text" class="ml-4" @click="cancelModal">{{
                $t("af:profile.modal.name.button.cancel")
              }}</b-button>
            </b-field>
          </validation-observer>
        </form>

        <div class="close-btn cursor-pointer" @click="cancelModal">
          <b-icon icon-pack="fas" icon="times" type="is-grey-icon" />
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import i18n from "@/plugins/i18n";

export default {
  name: "UserEditNameModal",
  components: {
    BInputWithValidation: () => import("@/components/BInputWithValidation.vue"),
  },
  props: {
    lastName: {
      type: String,
      required: false,
    },
    firstName: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      isModalOpen: false,
      lastNameEdit: "",
      firstNameEdit: "",
    };
  },
  methods: {
    openModal() {
      this.lastNameEdit = this.lastName;
      this.firstNameEdit = this.firstName;
      this.isModalOpen = true;
    },
    cancelModal() {
      this.isModalOpen = false;
    },
    async save() {
      const valid = await this.$refs.observer.validate();

      if (!valid) return;

      let customer = this.$store.getters["profile/get"];
      customer.lastName = this.lastNameEdit.trim().replace(/\s+/g, " ");
      customer.firstName = this.firstNameEdit.trim().replace(/\s+/g, " ");
      try {
        await this.$store.dispatch("profile/save", customer);
        this.$buefy.toast.open({
          message: i18n.t("af:profile.modal.name.message.successful_save"),
          type: "is-success",
        });
        this.cancelModal();
        this.$emit("save");
      } catch (e) {
        this.$buefy.toast.open({
          message: i18n.t("af:profile.modal.name.message.failed_save"),
          type: "is-danger",
        });
        customer.lastName = this.lastName;
        customer.firstName = this.firstName;
      }
    },
  },
};
</script>

<style scoped></style>
